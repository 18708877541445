import React from 'react';
import { Tooltip } from 'antd';
import { css } from '@emotion/css';

import { createMarkup } from '../utils';

type OverflowTextCellProps = {
  text: string;
  hoverText?: string;
  onClick?: VoidFunction;
};

export const OverflowTextCell = ({ text, hoverText, onClick }: OverflowTextCellProps) => {
  return (
    <Tooltip
      placement="topLeft"
      overlay={
        hoverText ? (
          <span>{hoverText}</span>
        ) : (
          <div
            dangerouslySetInnerHTML={createMarkup(text)}
            className={css`
              white-space: normal;
            `}
          />
        )
      }
      getPopupContainer={() => document.body}
    >
      <div
        className={css`
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        `}
        onClick={onClick}
        dangerouslySetInnerHTML={createMarkup(text)}
      />
    </Tooltip>
  );
};
