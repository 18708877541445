import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Form, Select } from 'antd';
import { isEmpty } from 'lodash';

import { generateSelectOptions } from '../utils';
import { Alerts, AlertsType, Entities, EntitiesType, Severities, SeveritiesType } from '../types';
import { useDataSource } from '../helpers/DataSourceContext';
import CModal from '../commons/c-modal/CModal';

interface FilterModalProps {
  refreshList: VoidFunction;
  onModalClose?: VoidFunction;
  selectedHosts: string[];
  selectedSources: string[];
  selectedEntitiesType: EntitiesType[];
  selectedAlertsType: AlertsType[];
  selectedSeveritiesType: SeveritiesType[];
  onApplyFilters: (item: any) => void;
  presettedHosts: string[];
}

export type FilterModalRef = {
  openModal: () => void;
};

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const entityTypeOptions = generateSelectOptions(Entities);
const alertTypeOptions = generateSelectOptions(Alerts);
const severityOptions = generateSelectOptions(Severities);

const FilterModal = forwardRef<FilterModalRef, FilterModalProps>(
  (
    {
      refreshList,
      onModalClose,
      selectedHosts,
      selectedSources,
      selectedEntitiesType,
      selectedAlertsType,
      selectedSeveritiesType,
      onApplyFilters,
      presettedHosts,
    },
    ref
  ) => {
    const [form] = Form.useForm();

    const [isModalVisible, setIsModalOpen] = useState<boolean>(false);
    const [hostOptions, setHostOptions] = useState<Array<{ label: string; value: string }>>([]);
    const [isLocationsLoading, setIsLocationsLoading] = useState<boolean>(false);

    const { dataSource } = useDataSource();

    useEffect(() => {
      const fetchLocations = async () => {
        try {
          setIsLocationsLoading(true);
          const locations = await dataSource?.services?.locationService?.getLocationsList(true, true, true);

          const _hostOptions = locations.flatMap((location: any) =>
            (location.groups || []).flatMap((group: any) =>
              (group.hosts || [])
                .map((host: any) => ({
                  label: `${location.name} : ${group.name} : ${host.name}`,
                  value: host.uuid,
                }))
                .filter((host: any) => (presettedHosts.length ? presettedHosts.includes(host.value) : true))
            )
          );

          setHostOptions(_hostOptions);
        } finally {
          setIsLocationsLoading(false);
        }
      };

      if (!isEmpty(dataSource)) {
        void fetchLocations();
      }
    }, [dataSource, presettedHosts]);

    const openModal = () => {
      form.setFieldsValue({
        host_uuid: selectedHosts,
        source: selectedSources,
        entity_type: selectedEntitiesType,
        type: selectedAlertsType,
        severity: selectedSeveritiesType,
      });
      setIsModalOpen(true);
    };

    const closeModal = () => {
      // Not resetting form with ```form.resetFields()``` as not to visually remove presetted value
      onModalClose?.();
      setIsModalOpen(false);
    };

    const handleSubmit = (item: any) => {
      onApplyFilters(item);
      setIsModalOpen(false);
    };

    useImperativeHandle(ref, () => ({
      openModal: () => {
        openModal();
      },
    }));

    return (
      <CModal
        title="Filter Alerts"
        open={isModalVisible}
        onCancel={closeModal}
        onOk={form.submit}
        maskClosable={false}
        closable={false}
        cancelText="Reset & Close Filters"
      >
        <Form {...formLayout} form={form} name="control-hooks" onFinish={handleSubmit} style={{ maxWidth: '42rem' }}>
          <Form.Item name="host_uuid" label="Host">
            <Select
              mode="multiple"
              placeholder="Select one or more host"
              options={hostOptions}
              loading={isLocationsLoading}
              allowClear
            />
          </Form.Item>

          <Form.Item name="source" label="Source">
            <Select
              placeholder="Enter multiple sources with commas"
              mode="tags"
              tokenSeparators={[',']}
              suffixIcon={null}
              dropdownStyle={{ display: 'none' }}
              allowClear
            />
          </Form.Item>

          <Form.Item name="entity_type" label="Entity Type">
            <Select
              mode="multiple"
              placeholder="Select one or more entity type"
              options={entityTypeOptions}
              allowClear
            />
          </Form.Item>

          <Form.Item name="type" label="Alert Type">
            <Select mode="multiple" placeholder="Select one or more alert type" options={alertTypeOptions} allowClear />
          </Form.Item>

          <Form.Item name="severity" label="Severity">
            <Select mode="multiple" placeholder="Select one or more severity" options={severityOptions} allowClear />
          </Form.Item>
        </Form>
      </CModal>
    );
  }
);

FilterModal.displayName = 'FilterModal';

export default FilterModal;
