import React, { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Spin, Table } from 'antd';
import { GrafanaTheme2, PanelData } from '@grafana/data';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { css, cx } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { locationService } from '@grafana/runtime';

import TableHeader from './TableHeader';
import AlertTransactions from './AlertTransactions';
import CreateModal, { CreateModalRef } from './CreateModal';
import ColumnOrganizeModal, { basicFixed, basicPreset, ColumnOrganizeModalRef } from './ColumnOrganizeModal';
import AdvanceSearchModal, { AdvanceSearchModalRef, IntervalByType } from './AdvanceSearchModal';
import FCMServerModal, { FCMServerModalRef } from './FCMServerModal';
import CreateTicketModal, { CreateTicketModalRef } from './CreateTicketModal';
import { AlertsTableContextMenu } from './AlertsTableContextMenu';
import ContextMenuModals from './ContextMenuModals';
import { useAlertsTableColumns } from '../hooks/useAlertsTableColumns';
import { useContextMenu } from '../hooks/useContextMenu';
import { useCalculateColumnsWidth } from '../hooks/useCalculateColumnsWidth';
import { useDataSource } from '../helpers/DataSourceContext';
import { usePanelData } from '../helpers/PanelDataContext';
import { dataFieldKeys, getFieldValue } from '../utils';
import { useHighlightEditedRow } from '../hooks/useHighlightEditedRow';
import { AlertsStatuses, AlertsStatusesType, AlertsType, EntitiesType, SeveritiesType } from '../types';
import { useDebounce } from '../hooks/useDebounce';
import { useTeamsStore } from '../hooks/useTeamsStore';
import FilterModal, { FilterModalRef } from './FilterModal';

dayjs.extend(isBetween);

interface AlertsTableProps {
  panelData: PanelData;
  dataSource: any;
  panelHeight: number;
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    connection: css({
      color: '#c92020',
    }),
    isEdited: css({
      animationName: 'blinker',
      animationDuration: '1s',
      animationIterationCount: 2,
      animationTimingFunction: 'linear',
    }),
    disableOrFault: css({
      background: theme.isDark ? '#2b1d11' : '#fff7e6',
    }),
  };
};

const AlertsTable = ({ dataSource, panelData, panelHeight }: AlertsTableProps) => {
  const { setDataSource } = useDataSource();
  const { setPanelData } = usePanelData();
  const isEditing = locationService.getSearchObject().editPanel !== undefined;

  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState<any>([]);
  const [dataBySearch, setDataBySearch] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [selectedAlerts, setSelectedAlerts] = useState<any[]>([]);
  const [selectedAlertUUID, setSelectedAlertUUID] = useState<string>('');
  const [selectedAlertStatuses, setSelectedAlertStatuses] = useState<AlertsStatusesType[]>([
    AlertsStatuses[0],
    AlertsStatuses[1],
  ]);
  const [search, setSearch] = useState<string>('');
  const [timeInterval, setTimeInterval] = useState<string[] | null>(null);
  const [intervalBy, setIntervalBy] = useState<IntervalByType | undefined>(undefined);
  const [selectedCols, setSelectedCols] = useState<string[]>(basicPreset);
  const [fixedCols, setFixedCols] = useState<string[]>(basicFixed);
  const [presettedHosts, setPresettedHosts] = useState<string[]>([]);
  const [presettedSources, setPresettedSources] = useState<string[]>([]);
  const [presettedEntitiesType, setPresettedEntitiesType] = useState<EntitiesType[]>([]);
  const [presettedAlertsType, setPresettedAlertsType] = useState<AlertsType[]>([]);
  const [presettedSeveritiesType, setPresettedSeveritiesType] = useState<SeveritiesType[]>([]);
  const [selectedHosts, setSelectedHosts] = useState<string[]>([]);
  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const [selectedEntitiesType, setSelectedEntitiesType] = useState<EntitiesType[]>([]);
  const [selectedAlertsType, setSelectedAlertsType] = useState<AlertsType[]>([]);
  const [selectedSeveritiesType, setSelectedSeveritiesType] = useState<SeveritiesType[]>([]);
  const [presettedFilterString, setPresettedFilterString] = useState<string | null>(null);
  const [filterString, setFilterString] = useState<string | undefined>(undefined);

  const createModalRef = useRef<CreateModalRef>(null);
  const columnOrganizeModalRef = useRef<ColumnOrganizeModalRef>(null);
  const advanceSearchModalRef = useRef<AdvanceSearchModalRef>(null);
  const fcmServerModalRef = useRef<FCMServerModalRef>(null);
  const createTicketModalRef = useRef<CreateTicketModalRef>(null);
  const filterModalRef = useRef<FilterModalRef>(null);
  const isFilterFormPristine = useRef<boolean>(true);
  const hasPresettedHosts = useRef<boolean>(false);

  const { handleRightClick, isContextMenuVisible, contextMenuData } = useContextMenu<any>();
  const { handleFetchSuccess } = useHighlightEditedRow({ setFilteredData: setData });
  const { setTeams, setIsTeamsLoading } = useTeamsStore();

  const fieldValue = getFieldValue(dataFieldKeys.CONFIG, panelData);

  const fetch = useCallback(
    async ({ editedRowId }: { editedRowId?: string } = {}) => {
      try {
        const alertResponse =
          (await dataSource?.services?.alertService?.fetchAlerts(
            selectedAlertStatuses,
            [...(isEditing ? presettedHosts : selectedHosts)],
            [...(isEditing ? presettedSources : selectedSources)],
            [...(isEditing ? presettedEntitiesType : selectedEntitiesType)],
            [...(isEditing ? presettedAlertsType : selectedAlertsType)],
            [...(isEditing ? presettedSeveritiesType : selectedSeveritiesType)]
          )) || [];
        handleFetchSuccess(alertResponse, editedRowId);
      } finally {
        setIsFetching(false);
      }
    },
    [
      selectedAlertStatuses,
      presettedHosts,
      presettedSources,
      presettedEntitiesType,
      presettedAlertsType,
      presettedSeveritiesType,
      selectedHosts,
      selectedSources,
      selectedEntitiesType,
      selectedAlertsType,
      selectedSeveritiesType,
      handleFetchSuccess,
      isEditing,
    ]
  );

  const fetchTeams = useCallback(async () => {
    try {
      setIsTeamsLoading(true);
      const teams = await dataSource?.services?.teamService?.fetchTeams();
      setTeams(teams);
    } finally {
      setIsTeamsLoading(false);
    }
  }, []);

  useEffect(() => {
    setDataSource(dataSource);
    setPanelData(panelData);
  }, [dataSource, panelData]);

  useEffect(() => {
    (async () => {
      if (presettedFilterString !== null || filterString !== undefined) {
        setIsFetching(true);
        await fetch();
      }
    })();
  }, [selectedAlertStatuses.length, presettedFilterString, filterString]);

  useEffect(() => {
    (async () => {
      await fetchTeams();
    })();
  }, []);

  const debouncedSearch = useDebounce<string>(search, 500);

  useEffect(() => {
    const res = data.filter((anAlert: any) => {
      let conditionCheck = true;
      if (debouncedSearch !== '') {
        // Checks whether the search keyword is a substring to either the alert title or the body
        conditionCheck =
          (anAlert?.title && (anAlert?.title).toLowerCase().includes(debouncedSearch.toLowerCase())) ||
          false ||
          (anAlert?.body && (anAlert?.body).toLowerCase().includes(debouncedSearch.toLowerCase())) ||
          false;
      }
      // Check whether the current alert is within a specified time interval
      if (timeInterval !== null && intervalBy !== undefined) {
        conditionCheck = conditionCheck && dayjs(anAlert[intervalBy]).isBetween(timeInterval[0], timeInterval[1]);
      }
      return conditionCheck;
    });

    setDataBySearch(res);
  }, [debouncedSearch, data, timeInterval, intervalBy]);

  useEffect(() => {
    if (selectedAlertUUID) {
      createTicketModalRef.current?.openModal();
    }
  }, [selectedAlertUUID]);

  const handleCreate = () => {
    createModalRef.current?.openModal();
  };

  const handleColReorganize = () => {
    columnOrganizeModalRef.current?.openModal();
  };

  const handleAdvanceSearch = () => {
    advanceSearchModalRef.current?.openModal();
  };

  const handleFCMServerClick = () => {
    fcmServerModalRef.current?.openModal();
  };

  const deleteAlert = useCallback(async (uuid: string) => {
    try {
      return await dataSource?.services?.alertService?.removeById(uuid);
    } catch {}
  }, []);

  const handleBulkDelete = async () => {
    try {
      await Promise.all(
        selectedRowKeys.map(async (uuid: string) => {
          return await deleteAlert(uuid);
        })
      );
      setSelectedRowKeys([]);
      await fetch();
    } catch {}
  };

  const handleRefresh = async () => {
    await fetch();
    await fetchTeams();
  };

  const handleSaveTags = async (uuid: string, alertBody: any) => {
    await dataSource?.services?.alertService?.updateAlertTag(uuid, alertBody?.tags || []);
  };

  const handleSaveMetaTags = async (uuid: string, data: any) => {
    await dataSource?.services?.alertService?.updateAlertMetaTag(uuid, data);
  };

  const isShowFilteredData = () => {
    return search === '' && timeInterval === null;
  };

  const handleFilterAlert = () => {
    filterModalRef.current?.openModal();
  };

  useEffect(() => {
    setFilterString((prevFilterString) => {
      const params = new URLSearchParams(prevFilterString);
      params.delete('host_uuid');
      if (selectedHosts.length > 0) {
        selectedHosts.forEach((value: any) => params.append('host_uuid', value));
      }
      return params.toString();
    });
  }, [selectedHosts]);

  useEffect(() => {
    setFilterString((prevFilterString) => {
      const params = new URLSearchParams(prevFilterString);
      params.delete('source');
      if (selectedSources.length > 0) {
        selectedSources.forEach((value: any) => params.append('source', value));
      }
      return params.toString();
    });
  }, [selectedSources]);

  useEffect(() => {
    setFilterString((prevFilterString) => {
      const params = new URLSearchParams(prevFilterString);
      params.delete('entity_type');
      if (selectedEntitiesType.length > 0) {
        selectedEntitiesType.forEach((value: any) => params.append('entity_type', value));
      }
      return params.toString();
    });
  }, [selectedEntitiesType]);

  useEffect(() => {
    setFilterString((prevFilterString) => {
      const params = new URLSearchParams(prevFilterString);
      params.delete('type');
      if (selectedAlertsType.length > 0) {
        selectedAlertsType.forEach((value: any) => params.append('type', value));
      }
      return params.toString();
    });
  }, [selectedAlertsType]);

  useEffect(() => {
    setFilterString((prevFilterString) => {
      const params = new URLSearchParams(prevFilterString);
      params.delete('severity');
      if (selectedSeveritiesType.length > 0) {
        selectedSeveritiesType.forEach((value: any) => params.append('severity', value));
      }
      return params.toString();
    });
  }, [selectedSeveritiesType]);

  const processFilter = useCallback(
    (
      key: string,
      values: any[],
      params: URLSearchParams | undefined,
      setSelected: SetStateAction<any>,
      addToFilterParam = true,
      doUnion = false
    ) => {
      if (Array.isArray(values)) {
        if (key === 'presetted_host_uuid') {
          hasPresettedHosts.current = values.length > 0;
        }
        setSelected((prevValues: any) => {
          if (!doUnion) {
            if (key === 'host_uuid' && hasPresettedHosts.current && values.length <= 0) {
              return prevValues; // Preserve previous values if no valid operation can be performed
            }
            return values; // Replace with new values
          }
          if (key === 'host_uuid' && hasPresettedHosts.current) {
            const unionValue = prevValues.filter((pv: any) => values.includes(pv));
            return unionValue.length > 0 ? unionValue : values;
          }
          return prevValues;
        });

        if (addToFilterParam) {
          if (values.length > 0) {
            values.forEach((value: any) => params?.append(key, value));
          } else {
            params?.delete(key);
          }
        }
      }
    },
    []
  );

  const handleApplyFilters = useCallback(
    (item: any, fromQuery = false, doUnion = false) => {
      processFilter('host_uuid', item?.host_uuid, undefined, setSelectedHosts, false, doUnion);
      processFilter('source', item?.source, undefined, setSelectedSources, false, doUnion);
      processFilter('entity_type', item?.entity_type, undefined, setSelectedEntitiesType, false, doUnion);
      processFilter('type', item?.type, undefined, setSelectedAlertsType, false, doUnion);
      processFilter('severity', item?.severity, undefined, setSelectedSeveritiesType, false, doUnion);

      if (!fromQuery) {
        isFilterFormPristine.current = false;
      }
    },
    [processFilter]
  );

  const handleApplyPresettedFilters = useCallback(
    (item: any) => {
      const params = new URLSearchParams();

      processFilter('presetted_host_uuid', item?.host_uuid, params, setPresettedHosts, isEditing);
      processFilter('presetted_source', item?.source, params, setPresettedSources, isEditing);
      processFilter('presetted_entity_type', item?.entity_type, params, setPresettedEntitiesType, isEditing);
      processFilter('presetted_type', item?.type, params, setPresettedAlertsType, isEditing);
      processFilter('presetted_severity', item?.severity, params, setPresettedSeveritiesType, isEditing);

      setPresettedFilterString(params.toString());
    },
    [processFilter, isEditing]
  );

  useEffect(() => {
    const item = {
      host_uuid: (fieldValue?.host_uuids ?? []).length > 0 ? fieldValue.host_uuids.map((item: any) => item?.value) : [],
      source: fieldValue?.sources ?? [],
      entity_type: (fieldValue?.entities ?? []).length > 0 ? fieldValue.entities.map((item: any) => item?.value) : [],
      type: (fieldValue?.alertTypes ?? []).length > 0 ? fieldValue.alertTypes.map((item: any) => item?.value) : [],
      severity: (fieldValue?.severities ?? []).length > 0 ? fieldValue.severities.map((item: any) => item?.value) : [],
    };
    handleApplyPresettedFilters(item);
    if (!isEditing) {
      handleApplyFilters(item, isFilterFormPristine.current, !isFilterFormPristine.current);
    }
  }, [fieldValue, handleApplyPresettedFilters, handleApplyFilters, isEditing]);

  const handleFilterModalClose = useCallback(() => {
    const item = {
      host_uuid: (fieldValue?.host_uuids ?? []).length > 0 ? fieldValue.host_uuids.map((item: any) => item?.value) : [],
      source: fieldValue?.sources ?? [],
      entity_type: (fieldValue?.entities ?? []).length > 0 ? fieldValue.entities.map((item: any) => item?.value) : [],
      type: (fieldValue?.alertTypes ?? []).length > 0 ? fieldValue.alertTypes.map((item: any) => item?.value) : [],
      severity: (fieldValue?.severities ?? []).length > 0 ? fieldValue.severities.map((item: any) => item?.value) : [],
    };
    isFilterFormPristine.current = true;
    handleApplyFilters(item, true);
  }, [fieldValue]);

  const rowSelection = {
    columnWidth: 48,
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedAlerts(selectedRows);
    },
  };

  const { tableColumns } = useAlertsTableColumns(setSelectedAlertUUID, fetch, selectedCols, fixedCols);
  const {
    columns: calcColumns,
    source,
    tableWidth,
  } = useCalculateColumnsWidth(tableColumns, isShowFilteredData() ? data : dataBySearch, 600);

  const tableHeaderHeight = useMemo(() => {
    const headerEl = document.getElementById('alert-table-header');
    if (headerEl) {
      return headerEl?.clientHeight;
    }
    return 0;
  }, [panelHeight, source]);

  const styles = useStyles2(getStyles);

  return (
    <>
      <div id="alerts-table-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <TableHeader
          data={data}
          search={search}
          setSearch={setSearch}
          hostUUID={fieldValue?.host_uuid}
          onRefresh={handleRefresh}
          onCreateAlert={handleCreate}
          bulkDelete={handleBulkDelete}
          onFCMServerClick={handleFCMServerClick}
          selectedAlertStatuses={selectedAlertStatuses}
          setSelectedAlertStatuses={setSelectedAlertStatuses}
          onColReorganize={handleColReorganize}
          onAdvanceSearch={handleAdvanceSearch}
          isAdvanceSearchActive={timeInterval !== null && intervalBy !== undefined}
          onFilterAlert={handleFilterAlert}
          isEditing={isEditing}
        />

        <Table
          id="alerts-table"
          rowKey="uuid"
          columns={calcColumns}
          dataSource={source}
          loading={{ indicator: <Spin />, spinning: isFetching }}
          rowSelection={rowSelection}
          virtual
          scroll={{
            x: tableWidth,
            y: panelHeight - 120 - tableHeaderHeight,
          }} // All height and margin/padding adds up to 120
          showSorterTooltip={false}
          expandable={{
            columnWidth: 32,
            expandedRowRender: (record: any, _index: number, _indent: number, expanded: boolean) => (
              <AlertTransactions alertUUID={record.uuid} isExpanded={expanded} />
            ),
          }}
          pagination={{
            position: ['bottomLeft'],
            showSizeChanger: true,
            pageSizeOptions: [10, 50, 100, 1000],
            locale: { items_per_page: '' },
            defaultPageSize: 50,
          }}
          onRow={(record: any) => {
            return {
              onContextMenu: (event) => {
                handleRightClick(record, event);
              },
            };
          }}
          rowClassName={(record: any) =>
            cx(
              record?.connection === 'Broken' && styles.connection,
              record?.isEdited && styles.isEdited,
              (!record?.enable || record?.fault) && styles.disableOrFault
            )
          }
        />
      </div>

      <CreateModal ref={createModalRef} refreshList={fetch} />

      <ColumnOrganizeModal
        ref={columnOrganizeModalRef}
        setReorgCols={setSelectedCols}
        setFixedCols={setFixedCols}
        tableColumns={tableColumns}
      />

      <AdvanceSearchModal
        ref={advanceSearchModalRef}
        timeInterval={timeInterval}
        setTimeInterval={setTimeInterval}
        intervalBy={intervalBy}
        setIntervalBy={setIntervalBy}
      />

      <FCMServerModal ref={fcmServerModalRef} />

      <CreateTicketModal ref={createTicketModalRef} alertUUID={selectedAlertUUID} />

      <AlertsTableContextMenu
        contextMenuData={contextMenuData}
        isVisible={isContextMenuVisible}
        selectedUUIDs={selectedRowKeys}
        bulkDelete={handleBulkDelete}
      />

      <ContextMenuModals
        refreshList={fetch}
        selectedAlertUUIDs={selectedRowKeys}
        selectedAlerts={selectedAlerts}
        handleSaveTags={handleSaveTags}
        handleSaveMetaTags={handleSaveMetaTags}
        clearSelection={() => {
          setSelectedRowKeys([]);
          setSelectedAlerts([]);
        }}
      />

      <FilterModal
        ref={filterModalRef}
        refreshList={fetch}
        selectedHosts={selectedHosts}
        selectedSources={selectedSources}
        selectedEntitiesType={selectedEntitiesType}
        selectedAlertsType={selectedAlertsType}
        selectedSeveritiesType={selectedSeveritiesType}
        onModalClose={handleFilterModalClose}
        onApplyFilters={handleApplyFilters}
        presettedHosts={presettedHosts}
      />
    </>
  );
};

export default AlertsTable;
